<template>
  <div id="app">
    <FormFloat />
  </div>
</template>

<script>

import FormFloat from './components/FormFloat.vue'

export default {
  name: 'App',
  components: {
    FormFloat
  },
  data: () => ({
    code: '',
    linkColor: '#ddd',
    background: '#333',
    navLinks: [
      {
        text: 'Home',
        path: '/home',
      },
      {
        text: 'Popular Items',
        path: '/popular',
      },
      {
        text: 'Coupons & Specials',
        path: '/coupons',
      },
      {
        text: 'Pantry',
        path: '/pantry',
      },
      {
        text: 'Drink',
        path: '/drink',
      },
      {
        text: 'Eat',
        path: '/eat',
      },
      {
        text: 'Face Masks',
        path: '/masks',
      },
      {
        text: 'Retail & Gifts',
        path: '/gifts',
      },
    ]
  })
}
</script>

