<template>
  <div id="float-form">
    <header class="form-header" v-if="step != 4">
      <div>
        <img v-if="step == 1" class="step" src="../assets/img/step1.png" alt="Step 1">
        <img v-if="step == 2" class="step" src="../assets/img/step2.png" alt="Step 2">
        <img v-if="step == 3" class="step" src="../assets/img/step3.png" alt="Step 3">
      </div>
      <div>
        <h1>Выбор услуги</h1>
        <h2 v-if="step == 1">Шаг 2: Выбор даты</h2>
        <h2 v-if="step == 2">Шаг 3: Контактные данные</h2>
      </div>
      <div @click="hideForm">
        <img class="close" src="../assets/img/close.png" alt="Close">
      </div>
    </header>
    <header class="form-header" style="background: #fff" v-if="step == 4">
      <div @click="hideForm">
        <img class="close" src="../assets/img/close-2.png" alt="Close">
      </div>
    </header>

    <!-- STEP  1 -->
    <section v-if="step == 1">
      <div v-for="(category, index) in categories" v-bind:key="index">
        <div class="category">
          {{ category.name }} <img class="arrow-down" src="../assets/img/arrow-down.png">
        </div>
        <div class="category-items">
          <div @click="step = 2; selectedItem = item" v-for="(item, index) in category.items" v-bind:key="index">
            {{ item }} <img class="arrow-right" src="../assets/img/arrow-right.png">
          </div>
        </div>
      </div>
    </section>

    <!-- STEP  2 -->
    <section v-if="step == 2">
      <div class="content">
        <b>Услуга: </b>{{selectedItem}}
      </div>
      <hr/>
      <flat-pickr 
        v-model="date"
        :config="config"                                                          
      ></flat-pickr>
      <footer>
        <button class="previous" @click="step = step - 1">
          Услуги
        </button>
        <button class="next" :class="date ? '' : 'disabled'" @click="nextStep">
          Оставить контакты
        </button>
      </footer>
    </section>

    <!-- STEP  3 -->
    <section v-if="step == 3">
      <div class="content">
        <b>Услуга: </b>{{selectedItem}}<br>
        <b>Дата: </b>{{formattedDate}}
        <hr />
        <label>Имя (обязательно) <span class="required">*</span></label>
        <input v-model="name" :class="nameValidation" type="text" @change="validateName">
        <div v-if="validName == false" class="red-text">Пожалуйста, введите Ваше имя.</div>
        <label>Телефон (обязательно) <span class="required">*</span></label>
        <input v-model="phone" :class="phoneValidation" type="number" @change="validatePhone" @keypress="isNumber($event)">
        <div v-if="validPhone == false" class="red-text">Пожалуйста, введите номер телефона.</div>
        <label>Приложить фото</label>
        <div class="image-input"  @click="trigger">{{imageInputLabel}}</div>
        <input type="file" ref="fileInput" style="display: none" @change="previewFile">
        <label>Комментарий</label>
        <textarea v-model="textArea" maxlength="600" rows="8"></textarea>
        <div class="counter" :class="textAreaCount == 0 ? 'error-color' : ''">{{textAreaCount}}/600 символов</div>
        <p class="f-16">Указывая свои данные вы даёте согласие
        на обработку персональных данных,
        в соответствии с политикой
        конфиденциальности</p>
      </div>
      <footer>
        <button class="previous" @click="step = step - 1">
          Дата
        </button>
        <button class="next" :class="validForm ? '' : 'disabled'" @click="nextStep">
          Подать заявку
        </button>
      </footer>
    </section>

    <!-- STEP  4 -->
    <section v-if="step == 4" style="height: 100vh">
      <div class="text-center">
        <img class="success-icon" src="../assets/img/success.png" alt="">
        <div class="success-title">
          Спасибо, <br>что выбрали наш сервис!
        </div>
      </div>
      <p class="success-p">Мы свяжемся с Вами в ближайшее время.</p>
      <button class="btn-blue" @click="hideForm">Вернуться на сайт</button>
      <div class="socials">Следите за новостями: </div>
      <div class="social-icons">
        <a href="https://www.facebook.com/mathax.lv/" target="_blank">
          <img src="../assets/img/facebook.png" alt="" style="margin-right: 15px">
        </a>
        <a href="https://www.instagram.com/mathax.lv/" target="_blank">
          <img src="../assets/img/instagram.png" alt="">
        </a>
      </div>
    </section>
  </div>
</template>


<script>

  import flatPickr from 'vue-flatpickr-component';
  import 'flatpickr/dist/flatpickr.css';
  import {Russian} from 'flatpickr/dist/l10n/ru.js';

  import $ from 'jquery'
  

  import axios from 'axios'

export default {
  props: [],
  components: {
    flatPickr,
  },
  data: () => ({
      formActive: true,
      step: 1,
      date: null,
      name: '',
      phone: '',
      file: null,
      selectedItem: null,
      validName: null,
      validPhone: null,
      validImage: null,
      textArea: '',
      categories: [
        {
          name: 'Кузовной ремонт',
          items: [
            'Покраска Кузова Авто',
            'Покраска в Раптор',
            'Выпрямление Вмятин Без Покраски',
          ]
        },
        {
          name: 'Полировка',
          items: [
            'Покраска Кузова Авто',
            'Покраска в Раптор',
            'Выпрямление Вмятин Без Покраски',
          ]
        },
        {
          name: 'Лобовое стекло',
          items: [
            'Покраска Кузова Авто',
            'Покраска в Раптор',
          ]
        },
      ],
      config: {
        locale: Russian,
        inline: true,
      }
  }),
  mounted: function() {
    // $('#float-form').show();
  },
  methods: {
    hideForm() {
      $('#float-form').hide();
      this.step = 1;
    },
    showForm() {
      $('#float-form').show();
    },
    trigger() {
      this.$refs.fileInput.click()
    },
    nextStep() {
      if (this.step == 2) {
        if (this.date) this.step = this.step + 1;
      }
      if (this.step == 3) {
        if (this.validName && this.validPhone && (this.textArea.length > 0)) {
          this.step = this.step + 1;

          let data = {
            name: this.name,
            phone: this.phone,
            textArea: this.textArea,
            file: this.file,
          }
          if (this.file) {
            data.file = this.file;
          }
          axios.post('https://www.mathax.lv/wp-admin/admin-ajax.php?action=send_form_email', data).then((response) => {
            console.log(response)
          })
        }
      }
    },
    validateName() {
      if (this.name.length > 2) {
        this.validName = true;
      } else {
        this.validName = false;
      }
    },
    validateImage() {
    },
    validatePhone() {
      if (this.phone.length > 9) {
        this.validPhone = true;
      } else {
        this.validPhone = false;
      }
    },
    isNumber: function (evt) {
      evt = evt ? evt : window.event;
      const charCode = evt.which ? evt.which : evt.keyCode;
      if (charCode > 31 && (charCode < 48 || charCode > 57) && charCode !== 46) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
    previewFile(event) {
      this.file = event.target.files[0];
    }
  },
  computed: {
    textAreaCount() {
      return this.textArea.length;
    },
    imageInputLabel() {
      if (this.file) return this.file.name;
      return 'Добавить фото (до 5 шт.)';
    },
    formattedDate() {
      const options = {weekday: 'long', year: 'numeric', month: 'long', day: 'numeric'};
      console.log(this.date);
      const newDate = new Date(this.date);
      console.log(newDate);
      return newDate.toLocaleDateString('ru', options);
    },
    validForm() {
      if (this.validName && this.validPhone && (this.textAreaCount > 0)) return true;
      return false;
    },
    nameValidation() {
      if (this.validName === true) return 'valid';
      if (this.validName === false) return 'error';
      return '';
    },
    phoneValidation() {
      if (this.validPhone === true) return 'valid';
      if (this.validPhone === false) return 'error';
      return '';
    }
  }
}
</script>

<style scoped>
  body {
    min-width: 0px !important;
  }
  footer {
    width:100%;
    background: #fff;
    padding-top: 20px;
    margin-bottom: 50px;
  }
  .counter {
    text-align: right;
    font-size: 14px;
  }
  .error-color {
    color: red;
  }
  textarea {
    width: 100%;
    border-radius: 5px;
    border-color: grey;
    padding: 15px;
  }
  label {
    color: grey;
  }
  .required {
    color: red;
  }
  input {
    width: 100%;
    font-size: 18px;
    margin-bottom: 20px;
    margin-top: 5px;
    padding: 15px !important;
    border-radius: 5px;
    border: 1px solid grey;
  }
  .image-input {
    width: 100%;
    font-size: 18px;
    margin-bottom: 20px;
    margin-top: 5px;
    padding: 15px;
    border-radius: 5px;
    border: 1px solid grey;
    padding-left: 50px;
    color: grey;
    background-image: url('../assets/img/image.png');
    background-position: center left;
    background-repeat: no-repeat;
    cursor: pointer;
  }
  input.valid {
    background-image: url('../assets/img/valid.png');
    background-position: center right;
    background-repeat: no-repeat;
    padding-right: 50px;
    width: 100%;
  }
  input.error {
    border: 1px solid red;
  }
  .red-text {
    color: red;
    font-size: 14px;
    margin-bottom: 10px;
    margin-top: -15px;
  }
  hr {
    margin: 20px 0px;
    border-top: 1px solid lightgrey;
  }
  h1 {
    margin: 0px;
    margin-left: 20px;
    margin-top: 5px;
    font-size: 26px;
  }
  h2 {
    margin: 0px;
    margin-left: 20px;
    margin-top: 10px;
    font-size: 18px;
    font-weight: 100;
  }
  .text-center {
    text-align: center;
  }
  section {
    padding: 20px;
    overflow: scroll;
    height: 80vh;
  }
  #float-form {
    position: fixed;
    top: 0px;
    left: 0px;
    background: #fff;
    height: 100vh;
    width: 100vw;
    z-index: 999;
    display:none;
  }
  .formActive {
    display: block !important;
  }
  .form-header {
    background-color: #000000;
    color: #fff;
    padding: 5px 10px;
    display: flex;
  }
  .close {
    position: absolute;
    right: 10px;
    top: 8px;
  }
  .category {
    padding: 25px 0px;
    color: #258FC4;
    font-weight: bold;
    cursor: pointer;
    text-transform: uppercase;
  }
  .category-items > div {
    padding: 20px 0px;
    font-size: 18px;
    cursor: pointer;
  }
  .close {
    height: 20px;
    width: 20px;
    cursor: pointer;
  }
  .step {
    width: 75px;
    height: 75px;
  }
  .success-icon {
    margin-top: 30px;
    width: 100px;
    height: 100px;
  }
  .success-title {
    font-weight: bold;
    font-size: 25px;
    margin-top: 20px;
  }
  .success-p {
    font-size: 18px;
  }
  .btn-blue {
    background: #258FC4;
    color: #fff;
    border: 0;
    width: 100%;
    padding: 15px;
    font-size: 20px;
    margin-top: 40px;
    border-radius: 5px;
  }
  .previous {
    background: #CDCCCC;
    color: #fff;
    border: 0;
    width: 30%;
    padding: 15px;
    font-size: 16px;
    border-radius: 5px;
    cursor: pointer;
  }
  .next {
    background: #258FC4;
    color: #fff;
    border: 0;
    width: 60%;
    padding: 15px;
    font-size: 16px;
    margin-left: 20px;
    border-radius: 5px;
    cursor: pointer;
  }
  .disabled {
    background: #9F9F9F;
    cursor: not-allowed;
  }
  .socials {
    margin-top: 50px;
    text-align: center;
    font-size: 24px;
  }
  .arrow-right {
    float: right;
  }
  .arrow-down {
    position: relative;
    top: 2px;
    left: 10px;
  }
  .flatpickr-input {
    display: none;
  }
  
  .content {
    padding: 0px 20px;
    font-size: 18px;
  }
  .f-16 {
    font-size: 16px;
  }
  .social-icons {
    text-align: center;
    margin-top: 10px;
  }
  .social-icons img {

  }
</style>

<style>
  .flatpickr-calendar.inline {
    margin: 0 auto !important;
  }
</style>
